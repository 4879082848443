import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchCorporateModal from './SearchCorporateModal';
import InputInvoiceModal from './InputInvoiceModal';
import InputTextBox from './InputTextBox';
import InputNumberBox from './InputNumberBox';
import InputReadonlyBox from '../components/InputReadonlyBox';
import searchIcon from '../images/search_icon.png';
import { validateInputs } from './validateInputs.js';
import { BUTTON_COLOR } from '../components/colors.js'
import axios from 'axios';
import '../contents.css';

// Modalのスタイル
const modalStyles = {
  content: {
    top: '10%',
    left: '10%',
    right: '10%',
  },
};

function CorporateBasicinfo(props) {

  const [isSearchCorporateModalOpen, setIsSearchCorporateModalOpen] = useState(false);
  const [isInputInvoiceModalOpen, setIsInputInvoiceModalOpen] = useState(false);

  // 法人番号から法人情報を自動入力する
  const handleClickAutoCorporateInfo = (value) => {
    const [isErr, message] = validateInputs({ corporateNumber: value });
    if (isErr) {
      props.setErrMessage({ ...props.errMessage, corporateNumber: message['corporateNumber'] });
    }
    else {
      axios
        .get(process.env.REACT_APP_API_DOMAIN + '/v1/corporate/' + value)
        .then(res => {
          props.setName(res.data.name);
          props.setNameFurigana(res.data.name_furigana);
          props.setPostalCode(res.data.registration_address.postal_code);
          props.setRegion(res.data.registration_address.region);
          props.setCity(res.data.registration_address.city);
          props.setAddress1(res.data.registration_address.address1);
          props.setAddress2(res.data.registration_address.address2);
          props.setErrMessage({ ...props.errMessage, corporateNumber: '', postalCode: '' });
        })
        .catch(() => {
          props.setErrMessage({ ...props.errMessage, corporateNumber: '一致する法人情報は見つかりませんでした。お手数ですが、手動でご入力ください。' });
        });
    }
  };

  // 郵便番号から住所情報を自動入力する
  const handleClickAutoAddressInfo = (value) => {
    axios
      .get('https://api.zipaddress.net/?zipcode=' + value)
      .then(res => {
        props.setRegion(res.data.data.pref);
        props.setCity(res.data.data.city);
        props.setAddress1(res.data.data.town);
        props.setAddress2('');
        props.setErrMessage({
          ...props.errMessage,
          postalCode: '',
          address1: '番地以降をご入力ください'
        });
      })
      .catch(() => {
        props.setErrMessage({ ...props.errMessage, postalCode: '一致する住所は見つかりませんでした。お手数ですが、手動でご入力ください。' });
      });
  };

  // 法人検索ダイアログの確定イベント
  const handleSearchCorporateModalConfirm = (value) => {
    setIsSearchCorporateModalOpen(false);
    props.setCorporateNumber(value.corporate_number);
    props.setName(value.name);
    props.setNameFurigana(value.name_furigana);
    props.setPostalCode(value.registration_address.postal_code);
    props.setRegion(value.registration_address.region);
    props.setCity(value.registration_address.city);
    props.setAddress1(value.registration_address.address1);
    props.setAddress2(value.registration_address.address2);
    props.setErrMessage({ ...props.errMessage, corporateNumber: '', postalCode: '' });
  }

  // 法人検索ダイアログを閉じるイベント
  const handleSearchCorporateModalClose = () => {
    setIsSearchCorporateModalOpen(false);
  }

  // インボイスダイアログの確定イベント
  const handleInputInvoiceModalConfirm = (value) => {
    setIsInputInvoiceModalOpen(false);
    props.setInvoiceNumber('T' + value);
  }

  // インボイスダイアログを閉じるイベント
  const handleInputInvoiceModalClose = () => {
    setIsInputInvoiceModalOpen(false);
  }

  return (
    <Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <FormControl name='corporateNumber' error={props.errMessage.corporateNumber} variant='standard' style={{ width: '100%', maxWidth: 400 }}>
          <FormLabel sx={{ color: '#1a1a1a' }}>
            <Stack direction='row'>
              <p>法人番号</p>
              <p className='optional'>任意</p>
            </Stack>
          </FormLabel>
          <Button variant='outlined' color="inherit" sx={BUTTON_COLOR} onClick={() => setIsSearchCorporateModalOpen(true)} style={{ width: '100%' }}>
            <p><img src={searchIcon} align='top' width='20' height='20' />法人番号はこちらから検索できます</p>
          </Button>
          <Modal hideBackdrop open={isSearchCorporateModalOpen} style={modalStyles} >
            <SearchCorporateModal
              handleConfirm={handleSearchCorporateModalConfirm}
              handleClose={handleSearchCorporateModalClose}
            />
          </Modal>
          <Stack direction='row' style={{ width: '100%', marginTop: 10 }}>
            <span style={{ width: 240, marginRight: 10 }}>
              <InputNumberBox
                id='corporateNumber'
                label=''
                placeholder='1234567890123'
                value={props.corporateNumber}
                onChange={props.setCorporateNumber}
                errMessage={props.errMessage.corporateNumber}
                isUseHelperText={true}
                isUseNecessity={false}
                isRequired={false}
              />
            </span>
            <span style={{ width: 150, marginTop: 2 }}>
              <Button variant='outlined' onClick={() => handleClickAutoCorporateInfo(props.corporateNumber)} style={{ width: '100%' }}>
                ▼ 自動反映
              </Button>
            </span>
          </Stack>
        </FormControl>
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputTextBox
          id='name'
          name='name'
          label='法人名'
          placeholder='一般社団法人授業目的公衆送信補償金等管理協会'
          value={props.name}
          onChange={props.setName}
          errMessage={props.errMessage.name}
          isUseHelperText={true}
          isUseNecessity={true}
          isRequired={true}
        />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputTextBox
          id='nameFurigana'
          name='nameFurigana'
          label='法人名（カタカナ）'
          placeholder='イッパンシャダンホウジンジュギョウモクテキコウシュウソウシンホショウキントウカンリキョウカイ'
          value={props.nameFurigana}
          onChange={props.setNameFurigana}
          errMessage={props.errMessage.nameFurigana}
          isUseHelperText={true}
          isUseNecessity={true}
          isRequired={true}
        />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <FormControl variant='standard' style={{ width: '100%', maxWidth: 400 }}>
          <Stack direction='row' style={{ width: '100%' }}>
            <span style={{ width: 240, marginRight: 10 }}>
              <InputNumberBox
                id='postalCode'
                name='postalCode'
                label='法人登記上の郵便番号'
                placeholder='1000014'
                value={props.postalCode}
                onChange={props.setPostalCode}
                errMessage={props.errMessage.postalCode}
                isUseHelperText={true}
                isUseNecessity={true}
                isRequired={true}
              />
            </span>
            <span style={{ width: 150, marginTop: 28 }}>
              <Button variant='outlined' onClick={() => handleClickAutoAddressInfo(props.postalCode)} style={{ width: '100%' }}>
                ▼ 自動反映
              </Button>
            </span>
          </Stack>
        </FormControl>
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputTextBox
          id='region'
          name='region'
          label='法人登記上の都道府県'
          placeholder='東京都'
          value={props.region}
          onChange={props.setRegion}
          errMessage={props.errMessage.region}
          isUseHelperText={true}
          isUseNecessity={true}
          isRequired={true}
        />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputTextBox
          id='city'
          name='city'
          label='法人登記上の市区町村'
          placeholder='千代田区'
          value={props.city}
          onChange={props.setCity}
          errMessage={props.errMessage.city}
          isUseHelperText={true}
          isUseNecessity={true}
          isRequired={true}
        />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputTextBox
          id='address1'
          name='address1'
          label='法人登記上の町域・番地'
          placeholder='永田町１丁目１１番３０号'
          value={props.address1}
          onChange={props.setAddress1}
          errMessage={props.errMessage.address1}
          isUseHelperText={true}
          isUseNecessity={true}
          isRequired={true}
        />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputTextBox
          id='address2'
          name='address2'
          label='法人登記上の建物名など'
          placeholder='サウスヒル永田町5F'
          value={props.address2}
          onChange={props.setAddress2}
          errMessage={props.errMessage.address2}
          isUseHelperText={true}
          isUseNecessity={true}
          isRequired={false}
        />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputTextBox
          id='representativeName'
          name='representativeName'
          label='代表者氏名'
          placeholder='授業　太郎'
          value={props.representativeName}
          onChange={props.setRepresentativeName}
          errMessage={props.errMessage.representativeName}
          isUseHelperText={true}
          isUseNecessity={true}
          isRequired={true}
        />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputTextBox
          id='representativeNameFurigana'
          name='representativeNameFurigana'
          label='代表者氏名（カタカナ）'
          placeholder='ジュギョウ　タロウ'
          value={props.representativeNameFurigana}
          onChange={props.setRepresentativeNameFurigana}
          errMessage={props.errMessage.representativeNameFurigana}
          isUseHelperText={true}
          isUseNecessity={true}
          isRequired={true}
        />
      </Grid>

      <Grid item style={{ display: props.requiredInvoice ? 'block' : 'none', width: '100%', textAlign: 'center' }}>
        <FormControl name='invoiceNumber' variant='standard' style={{ width: '100%', maxWidth: 400 }}>
          <FormLabel sx={{ color: '#1a1a1a', textAlign: 'Left' }}>
            <p>適格請求書（インボイス）発行事業者登録番号</p>
            <p style={{ fontSize: '10px' }}>※インボイス発行事業者として登録を行っていない場合、入力の必要はありません。</p>
          </FormLabel>
          <Button variant='outlined' color="inherit" sx={BUTTON_COLOR} style={{ marginBottom: 5 }} onClick={() => setIsInputInvoiceModalOpen(true)} >
            <p><img src={searchIcon} align='top' width='20' height='20' />インボイス発行事業者登録番号を入力</p>
          </Button>

          <Modal hideBackdrop open={isInputInvoiceModalOpen} style={modalStyles} >
            <InputInvoiceModal
              handleConfirm={handleInputInvoiceModalConfirm}
              handleClose={handleInputInvoiceModalClose}
            />
          </Modal>
          <Stack direction='row' style={{ width: '100%' }}>
            <span style={{ width: 350 }}>
              <InputReadonlyBox
                value={props.invoiceNumber}
                errMessage={props.errMessage.invoiceNumber}
                isUseHelperText={true}
              />
            </span>
            <span style={{ width: 50, marginTop: 2 }}>
              <IconButton
                onClick={() => props.setInvoiceNumber('')}
                size='small'
              >
                <CloseIcon sx={{ color: '#D32F2F' }} />
              </IconButton>
            </span>
          </Stack>
          <p style={{ fontSize: '12px', color: 'red' }}>
            適格請求書（インボイス）発行事業者登録番号のご入力の有無により、補償金額が異なる場合があります。詳しくは分配業務受託団体等のウェブサイトやお問い合わせによりご確認ください。
          </p>
        </FormControl>
      </Grid>
    </Grid >
  )
}

export default CorporateBasicinfo