import React, { useState, useContext } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import { Context } from '../../Content';
import CustomButton from '../components/CustomButton'
import InputTextBox from '../components/InputTextBox';
import InputNumberBox from '../components/InputNumberBox';
import { validateInputs } from '../components/validateInputs.js';
import { smoothScroll } from '../components/smoothScroll.js';

function IndividualAddressInfoForm(props) {
  const [inputDataContext, isCorrectTransitionContext] = useContext(Context);
  const { inputData, setInputData } = inputDataContext;

  const [postalCode, setPostalCode] = useState(inputData['IndividualAddressInfoForm'] === undefined ? '' : inputData['IndividualAddressInfoForm'].postalCode);
  const [region, setRegion] = useState(inputData['IndividualAddressInfoForm'] === undefined ? '' : inputData['IndividualAddressInfoForm'].region);
  const [city, setCity] = useState(inputData['IndividualAddressInfoForm'] === undefined ? '' : inputData['IndividualAddressInfoForm'].city);
  const [address1, setAddress1] = useState(inputData['IndividualAddressInfoForm'] === undefined ? '' : inputData['IndividualAddressInfoForm'].address1);
  const [address2, setAddress2] = useState(inputData['IndividualAddressInfoForm'] === undefined ? '' : inputData['IndividualAddressInfoForm'].address2);
  const [errMessage, setErrMessage] = useState('');

  // 郵便番号変更イベント
  const handleChangePostalCode = (value) => {
    setPostalCode(value);
    axios
      .get('https://api.zipaddress.net/?zipcode=' + value)
      .then(res => {
        const success = res.data.code === 200;
        setRegion(prevState => success ? res.data.data.pref : prevState);
        setCity(prevState => success ? res.data.data.city : prevState);
        setAddress1(prevState => success ? res.data.data.town : prevState);
        setAddress2(prevState => success ? '' : prevState);
        setErrMessage({ ...errMessage, address1: success ? '番地以降をご入力ください' : '' });
      });
  };

  // ステップ移動時の処理
  const handleTransitionNext = () => {
    const json = { postalCode, region, city, address1, address2 };
    const [isErr, message] = validateInputs(json);
    setErrMessage([]);
    if (isErr) {
      setErrMessage(message);
      smoothScroll(Object.keys(message)[0]);
    }
    else {
      setInputData({ ...inputData, 'IndividualAddressInfoForm': json });
      props.handleTransition('/self/individual/accountinfo');
    }
  };

  return (
    <Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
      <Grid item alignItems='flex-start' style={{ width: '100%' }}>
        <p>権利者個人の住所をご入力ください。</p>
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputNumberBox
          id='postalCode'
          name='postalCode'
          label='郵便番号'
          placeholder='1000014'
          value={postalCode}
          onChange={handleChangePostalCode}
          errMessage={errMessage.postalCode}
          isUseHelperText={true}
          isUseNecessity={true}
          isRequired={true}
          dataTestId='postal-code'
        />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputTextBox
          id='region'
          name='region'
          label='都道府県'
          placeholder='東京都'
          value={region}
          onChange={setRegion}
          errMessage={errMessage.region}
          isUseHelperText={true}
          isUseNecessity={true}
          isRequired={true}
          dataTestId='region'
        />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputTextBox
          id='city'
          name='city'
          label='市区町村'
          placeholder='千代田区'
          value={city}
          onChange={setCity}
          errMessage={errMessage.city}
          isUseHelperText={true}
          isUseNecessity={true}
          isRequired={true}
          dataTestId='city'
        />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputTextBox
          id='address1'
          name='address1'
          label='町域・番地'
          placeholder='永田町１丁目１１番３０号'
          value={address1}
          onChange={setAddress1}
          errMessage={errMessage.address1}
          isUseHelperText={true}
          isUseNecessity={true}
          isRequired={true}
          dataTestId='address1'
        />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputTextBox
          id='address2'
          name='address2'
          label='建物名など'
          placeholder='サウスヒル永田町5F'
          value={address2}
          onChange={setAddress2}
          isUseHelperText={false}
          isUseNecessity={true}
          isRequired={false}
          dataTestId='address2'
        />
      </Grid>

      <Grid item>
        <CustomButton
          label='次へ'
          variant='contained'
          onClick={handleTransitionNext}
        />
      </Grid>

      <Grid item>
        <CustomButton
          label='戻る'
          variant='text'
          onClick={() => props.handleTransition('/self/individual/basicinfo')}
        />
      </Grid>
    </Grid>
  )
}

export default IndividualAddressInfoForm